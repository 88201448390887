import React from "react";
// Stylesheet
import "./RootLayout.scss";
//components
import { Outlet } from "react-router-dom";
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";
//Images
const RootLayout = () => {
  return (
    <div className="">
      <NavigationBar />
      <Outlet />
      <Footer />
    </div>
  );
};
export default RootLayout;
