import React from "react";
// Stylesheet
import "./Footer.scss";
//Images
import mail from "./../Images/mail.svg";
import phone from "./../Images/phone.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <h2 className="footerHeader">Get In Touch</h2>
      <div className="contactDiv">
        <img src={mail} alt="email" />
        <p>info@tdves.com</p>
      </div>
      <div className="contactDiv">
        <img src={phone} alt="phone" />
        <p>+91-91060 89656 / +91-98257 00299</p>
      </div>
      '
    </footer>
  );
};
export default Footer;
