// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homepage {
  text-align: center;
}
.homepage .heropageText {
  margin: 4rem 10.5rem;
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 2rem;
  font-weight: 400;
  line-height: 207.023%;
}

@media (max-width: 600px) {
  .homepage .heropageText {
    margin: 2rem 1.5rem;
    font-size: 1rem;
    line-height: 207.023%;
    text-align: left;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Homepage.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,oBAAA;EACA,WAAA;EACA,4CAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;AAEJ;;AAEA;EAEI;IACE,mBAAA;IACA,eAAA;IACA,qBAAA;IACA,gBAAA;EAAJ;AACF","sourcesContent":[".homepage {\r\n  text-align: center;\r\n  .heropageText {\r\n    margin: 4rem 10.5rem;\r\n    color: #fff;\r\n    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\r\n    font-size: 2rem;\r\n    font-weight: 400;\r\n    line-height: 207.023%;\r\n  }\r\n}\r\n\r\n@media (max-width: 600px) {\r\n  .homepage {\r\n    .heropageText {\r\n      margin: 2rem 1.5rem;\r\n      font-size: 1rem;\r\n      line-height: 207.023%;\r\n      text-align: left;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
