import React from "react";
// Stylesheet
import "./Feature.scss";
//Images
import visual from "./../Images/3D.svg";

const Feature = ({ text, img }) => {
  return (
    <div className="feature">
      <img src={img} alt={text} className="featureImg" />
      <p className="featureText">{text}</p>
    </div>
  );
};
export default Feature;
