// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heroSection {
  position: relative;
  width: 100%;
  height: 100vh;
}
.heroSection .vr {
  width: 100%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.heroSection .heroText {
  color: #fff;
  font-size: 2.25rem;
  width: 80%;
  padding: 0 0 3.375rem;
  line-height: 130%;
  position: absolute;
  font-weight: 600;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
.heroSection .heroText span {
  font-size: 6rem;
  background: linear-gradient(88deg, #fa8a3f 5.24%, #2694ff 32.93%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 600px) {
  .heroSection {
    background-position: top;
    height: 60vh;
  }
  .heroSection .vr {
    top: 41%;
    width: 100%;
  }
  .heroSection .heroText {
    text-align: left;
    line-height: 207%;
    padding-bottom: 2rem;
    font-size: 0.875rem;
  }
  .heroSection .heroText span {
    font-size: 2rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/HeroSection.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EAKA,WAAA;EACA,aAAA;AAHF;AAIE;EACE,WAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AAFJ;AAIE;EACE,WAAA;EACA,kBAAA;EACA,UAAA;EACA,qBAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,SAAA;EACA,SAAA;EACA,6BAAA;AAFJ;AAGI;EACE,eAAA;EACA,iEAAA;EACA,qBAAA;EACA,6BAAA;EACA,oCAAA;AADN;;AAMA;EACE;IACE,wBAAA;IACA,YAAA;EAHF;EAIE;IACE,QAAA;IACA,WAAA;EAFJ;EAIE;IACE,gBAAA;IACA,iBAAA;IACA,oBAAA;IACA,mBAAA;EAFJ;EAGI;IACE,eAAA;EADN;AACF","sourcesContent":[".heroSection {\r\n  position: relative;\r\n  // background: url(\"./../Images/vrBG.svg\"), url(\"./../Images/circleBg.svg\");\r\n  // background-position: center;\r\n  // background-size: cover;\r\n  // background-repeat: no-repeat;\r\n  width: 100%;\r\n  height: 100vh;\r\n  .vr {\r\n    width: 100%;\r\n    position: absolute;\r\n    top: 45%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n  }\r\n  .heroText {\r\n    color: #fff;\r\n    font-size: 2.25rem;\r\n    width: 80%;\r\n    padding: 0 0 3.375rem;\r\n    line-height: 130%;\r\n    position: absolute;\r\n    font-weight: 600;\r\n    bottom: 0;\r\n    left: 50%;\r\n    transform: translate(-50%, 0);\r\n    span {\r\n      font-size: 6rem;\r\n      background: linear-gradient(88deg, #fa8a3f 5.24%, #2694ff 32.93%);\r\n      background-clip: text;\r\n      -webkit-background-clip: text;\r\n      -webkit-text-fill-color: transparent;\r\n    }\r\n  }\r\n}\r\n\r\n@media (max-width: 600px) {\r\n  .heroSection {\r\n    background-position: top;\r\n    height: 60vh;\r\n    .vr {\r\n      top: 41%;\r\n      width: 100%;\r\n    }\r\n    .heroText {\r\n      text-align: left;\r\n      line-height: 207%;\r\n      padding-bottom: 2rem;\r\n      font-size: 0.875rem;\r\n      span {\r\n        font-size: 2rem;\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
