import React from "react";
// Stylesheet
import "./Homepage.scss";
//Images
//component
import HeroSection from "../Components/HeroSection";
import Capabilities from "../Components/Capabilities";
import FeaturesSection from "../Components/FeaturesSection";

import visual from "./../Images/3D.svg";
import interactive from "./../Images/Interactive UI.svg";
import haptic from "./../Images/Haptic.svg";
import integration from "./../Images/Integration.svg";
import colab from "./../Images/Collab.svg";

const features = [
  { text: "3D Visualization and Simulation", img: visual },
  { text: "Interactive UI", img: interactive },
  { text: "Haptic Feedback", img: haptic },
  { text: "Scalability and Integration", img: integration },
  { text: "Real-Time Collaboration", img: colab },
];
const Homepage = () => {
  return (
    <div className="homepage">
      <HeroSection />
      <FeaturesSection features={features} />
      <Capabilities />
      <h2 className="heropageText">
        We empower you to effortlessly craft immersive VR simulations for the
        world of infrastructure and real estate, unlocking boundless
        possibilities for design, presentation, and exploration.
      </h2>
    </div>
  );
};
export default Homepage;
