import React from "react";
// Stylesheet
import "./HeroSection.scss";
//Images
import vr from "./../Images/VR.svg";

const HeroSection = () => {
  return (
    <div className="heroSection">
      <img src={vr} className="vr" alt="vr" />
      <h1 className="heroText">
        <span>XRINK</span> revolutionizes the way infrastructure and real estate
        projects come to life in stunning virtual reality simulations.
      </h1>
    </div>
  );
};
export default HeroSection;
