import React, { useState } from "react";
// Stylesheet
import "./NavigationBar.scss";
//Images
import xrinkLogo from "./../Images/xrinkLogo.svg";
import hamburgerMenu from "./../Images/menu.svg";
import close from "./../Images/close.svg";
import { NavLink } from "react-router-dom";

const NavigationBar = () => {
  const [isActive, setIsActive] = useState(false);
  const navHandler = () => {
    setIsActive((prev) => !prev);
  };
  return (
    <nav className="navigationBar">
      <NavLink to="/">
        <img src={xrinkLogo} alt="logo" className="logo" />
      </NavLink>
      <ul className={`navLinks ${isActive && "navLinksActive"}`}>
        <li>
          <NavLink to="/">Home</NavLink>
        </li>
        <li>
          <NavLink to="/about-us">About Us</NavLink>
        </li>
        <li>
          <img
            onClick={navHandler}
            className="closeBtn"
            src={close}
            alt="close"
          />
        </li>
      </ul>
      <img
        onClick={navHandler}
        src={hamburgerMenu}
        alt="menu"
        className="hamburgerMenu"
      />
    </nav>
  );
};
export default NavigationBar;
