// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.capabilities {
  margin: 0 10.5rem;
  text-align: left;
}
.capabilities .sectionHeader {
  font-weight: 500;
  font-size: 2rem;
  margin-bottom: 4rem;
  color: #fa8a3f;
}
.capabilities .contentSection {
  display: flex;
  flex-direction: column;
  gap: 6rem;
}

@media (max-width: 600px) {
  .capabilities {
    margin: 0 1.5rem;
  }
  .capabilities .sectionHeader {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .capabilities .contentSection {
    gap: 2rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Capabilities.scss","webpack://./src/Colors/color.scss"],"names":[],"mappings":"AACA;EACE,iBAAA;EACA,gBAAA;AAAF;AACE;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,cCPc;ADQlB;AACE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;;AAGA;EACE;IACE,gBAAA;EAAF;EACE;IACE,iBAAA;IACA,qBAAA;EACJ;EACE;IACE,SAAA;EACJ;AACF","sourcesContent":["@import \"./../Colors/color.scss\";\r\n.capabilities {\r\n  margin: 0 10.5rem;\r\n  text-align: left;\r\n  .sectionHeader {\r\n    font-weight: 500;\r\n    font-size: 2rem;\r\n    margin-bottom: 4rem;\r\n    color: $secondary-color;\r\n  }\r\n  .contentSection {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 6rem;\r\n  }\r\n}\r\n\r\n@media (max-width: 600px) {\r\n  .capabilities {\r\n    margin: 0 1.5rem;\r\n    .sectionHeader {\r\n      font-size: 1.5rem;\r\n      margin-bottom: 1.5rem;\r\n    }\r\n    .contentSection {\r\n      gap: 2rem;\r\n    }\r\n  }\r\n}\r\n","$primary-color: #0082ff;\r\n$secondary-color: #fa8a3f;\r\n$tertary-color: #f34213;\r\n$background-color: #191919;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
