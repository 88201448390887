import React, { useEffect, useState } from "react";
// Stylesheet
import "./SubPages.scss";
//Images
import heroImg1 from "./../Images/IndustrialVR/HeroImg1.svg";
import industryIcon from "./../Images/IndustrialVR/IndustryIcon.svg";
import bmi from "./../Images/IndustrialVR/BIM.svg";
import training from "./../Images/IndustrialVR/training.svg";
import collab from "./../Images/IndustrialVR/collab.svg";
import serve from "./../Images/IndustrialVR/Serve.svg";
import serveMobile from "./../Images/IndustrialVR/serveMobile.svg";
import benifitsImg from "./../Images/IndustrialVR/benefitsImg.svg";
import betterPerformance from "./../Images/IndustrialVR/betterperformance.svg";
import costBenefits from "./../Images/IndustrialVR/Costbenefits.svg";
import lessInjuries from "./../Images/IndustrialVR/LessInjuries.svg";

import heroImg2 from "./../Images/RealEstate/heroImgReal.svg";
import RealIcon from "./../Images/RealEstate/realestateIcon.svg";
import thirdDim from "./../Images/RealEstate/3D.svg";
import floorPlan from "./../Images/RealEstate/floorplan.svg";
import insight from "./../Images/RealEstate/insight.svg";
import serve2 from "./../Images/RealEstate/Serve2.svg";
import serve2Mobile from "./../Images/RealEstate/serve2Mobile.svg";
import benefitsImg2 from "./../Images/RealEstate/forRealImg.svg";
import Enhanced from "./../Images/RealEstate/LessInjuries.svg";
import improved from "./../Images/RealEstate/betterperformance.svg";
import Time from "./../Images/RealEstate/Costbenefits.svg";

import heroLine from "./../Images/IndustrialVR/lines.svg";
import bg from "./../Images/bg.svg";

import FeaturesSection from "../Components/FeaturesSection";
import SubHeader from "../Components/SubHeader";
import ContactUs from "../Components/ContactUs";
import { useParams } from "react-router-dom";

const subpageData = [
  {
    id: "industrial-vr-simulation",
    mainImg: heroImg1,
    mainHeader: "Industrial VR Simulation",
    subHeader:
      "Our Virtual Reality for Industrial Planning product is an innovative solution designed to revolutionize industrial planning and operations. By harnessing the power of virtual reality, we provide industries with an immersive and interactive platform to visualize, optimize, and streamline their processes.",
    headerIcon: industryIcon,
    features: [
      { text: "Real Time BIM Visualization", img: bmi },
      { text: "Guided Simulation for Trainings", img: training },
      { text: "Collaboration between Teams", img: collab },
    ],
    serve: serve,
    serveMobile: serveMobile,
    benifitsImg: benifitsImg,
    benifits: [
      {
        text: "Less Injuries",
        icon: betterPerformance,
      },
      {
        text: "Better Performance",
        icon: costBenefits,
      },
      {
        text: "Cost Benefits",
        icon: lessInjuries,
      },
    ],
  },

  {
    id: "real-estate-visualization",
    mainImg: heroImg2,
    mainHeader: "Real Estate Visualization",
    subHeader:
      "Introducing our Real Estate VR Visualization Planning product, a game-changing tool for the real estate industry. It offers an immersive journey into your property developments before construction begins. With photorealistic 3D models and interactive walkthroughs, you can showcase your projects to clients and investors, making decisions faster and with more confidence. Welcome to the future of property visualization.",
    headerIcon: RealIcon,
    features: [
      { text: "3D Visualization", img: thirdDim },
      { text: "Interactive Floor Plans", img: floorPlan },
      { text: "Location Insights on different floors", img: insight },
    ],
    serve: serve2,
    serveMobile: serve2Mobile,
    benifitsImg: benefitsImg2,
    benifits: [
      {
        text: "Enhanced Communication",
        icon: Enhanced,
      },
      {
        text: "Improved Marketing",
        icon: improved,
      },
      {
        text: "Time & Cost Efficiency",
        icon: Time,
      },
    ],
  },
];

const SubPages = () => {
  const [pageData, setPageData] = useState([]);
  const [showData, setShowData] = useState(false);
  const params = useParams();
  useEffect(() => {
    const data = subpageData.find((page) => page.id === params.benefitId);
    console.log(data);
    if (data) {
      setPageData(data);
      setShowData(true);
    }
  }, [params.benefitId]);
  return (
    <>
      {showData ? (
        <div className="subPage">
          <div className="subPageHeroSection">
            <img className="heroImg" src={pageData.mainImg} alt="img" />
            <h1 className="heroHeader">{pageData.mainHeader}</h1>
            <div className="textContentDiv">
              <div className="textContentSubDiv">
                <p className="subContentText">
                  {pageData.subHeader}
                  <img src={heroLine} alt="line" className="heroLine" />
                </p>
              </div>
              <div className="heroIconDiv">
                <img src={pageData.headerIcon} alt="" className="heroIcon" />
              </div>
            </div>
          </div>
          <div className="featureDiv">
            <SubHeader text={"Features"} />
            <FeaturesSection features={pageData.features} />
          </div>
          <div className="solutionSection">
            <SubHeader text={"Industries we serve"} />
            <div className="pictureDiv">
              <picture>
                <source
                  srcSet={pageData.serveMobile}
                  media="(max-width: 600px)"
                />
                <img srcSet={pageData.serve} alt="img" />
              </picture>
            </div>
          </div>
          <div className="benifits">
            <SubHeader text={"Benefits"} />
            <div className="beifitsContent">
              <div className="benifitsImgDiv">
                <img
                  className="contentImg"
                  src={pageData.benifitsImg}
                  alt="img"
                />
                <img className="contentBg" src={bg} alt="bg" />
              </div>
              <div className="benifitsTextDiv">
                {pageData.benifits.map((data, i) => (
                  <div key={i} className="benifitsContentSubDiv">
                    <img
                      className="benifitsIcon"
                      src={data.icon}
                      alt="performance"
                    />
                    <p className="benifitsText">{data.text}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* <ContactUs /> */}
        </div>
      ) : (
        <p>Loading</p>
      )}
    </>
  );
};
export default SubPages;
