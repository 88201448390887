import React from "react";
// Stylesheet
import "./ContactUs.scss";
import SubHeader from "./SubHeader";
import contact from "./../Images/contact.svg";
import arrow from "./../Images/arrow-btn.svg";
//Images
const ContactUs = () => {
  return (
    <div className="constactUs">
      <SubHeader text={"Contact Us"} />
      <div className="formContent">
        <img src={contact} className="formImg" alt="" />
        <div className="formInput">
          <input placeholder="Name" className="input" type="text" />
          <input placeholder="Email" className="input" type="text" />
          <input placeholder="Company" className="input" type="text" />
          <input placeholder="Contact No." className="input" type="text" />
        </div>
      </div>
      <button className="submitBtn">
        Submit <img src={arrow} alt="arrow" />
      </button>
    </div>
  );
};
export default ContactUs;
