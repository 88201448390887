import React from "react";
// Stylesheet
import "./AboutUsPage.scss";
//Images
const AboutUsPage = () => {
  return (
    <div className="">
      <p>About us</p>
    </div>
  );
};
export default AboutUsPage;
