// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.featuresSection {
  text-align: center;
  margin: 5rem 0;
}
.featuresSection .features {
  padding: 4rem 0;
  width: 80%;
  margin-inline: auto;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .featuresSection {
    padding: 0 1.5rem;
    margin: 1rem 0;
  }
  .featuresSection img {
    width: 100%;
  }
  .featuresSection .features {
    width: 100%;
    gap: 1rem;
    padding: 1.25rem 0;
    flex-wrap: wrap;
    justify-content: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/FeaturesSection.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;AACF;AAAE;EACE,eAAA;EACA,UAAA;EACA,mBAAA;EACA,aAAA;EAEA,8BAAA;AACJ;;AAEA;EACE;IACE,iBAAA;IACA,cAAA;EACF;EAAE;IACE,WAAA;EAEJ;EAAE;IACE,WAAA;IACA,SAAA;IACA,kBAAA;IACA,eAAA;IACA,uBAAA;EAEJ;AACF","sourcesContent":[".featuresSection {\r\n  text-align: center;\r\n  margin: 5rem 0;\r\n  .features {\r\n    padding: 4rem 0;\r\n    width: 80%;\r\n    margin-inline: auto;\r\n    display: flex;\r\n    //   flex-wrap: wrap;\r\n    justify-content: space-between;\r\n  }\r\n}\r\n@media (max-width: 600px) {\r\n  .featuresSection {\r\n    padding: 0 1.5rem;\r\n    margin: 1rem 0;\r\n    img {\r\n      width: 100%;\r\n    }\r\n    .features {\r\n      width: 100%;\r\n      gap: 1rem;\r\n      padding: 1.25rem 0;\r\n      flex-wrap: wrap;\r\n      justify-content: center;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
