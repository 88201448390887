// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.featureHeader {
  text-align: left;
  color: #0082ff;
  width: 80%;
  margin-inline: auto;
  font-size: 3rem;
  margin-bottom: 5rem;
}

@media (max-width: 600px) {
  .featureHeader {
    text-align: center;
    font-size: 1.25rem;
    margin-bottom: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/SubHeader.scss","webpack://./src/Colors/color.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,cCJc;EDKd,UAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;AADF;;AAIA;EACE;IACE,kBAAA;IACA,kBAAA;IACA,gBAAA;EADF;AACF","sourcesContent":["@import \"./../Colors/color.scss\";\r\n\r\n.featureHeader {\r\n  text-align: left;\r\n  color: $primary-color;\r\n  width: 80%;\r\n  margin-inline: auto;\r\n  font-size: 3rem;\r\n  margin-bottom: 5rem;\r\n}\r\n\r\n@media (max-width: 600px) {\r\n  .featureHeader {\r\n    text-align: center;\r\n    font-size: 1.25rem;\r\n    margin-bottom: 0;\r\n  }\r\n}\r\n","$primary-color: #0082ff;\r\n$secondary-color: #fa8a3f;\r\n$tertary-color: #f34213;\r\n$background-color: #191919;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
