import React from "react";
// Stylesheet
import "./ContentSubSection.scss";
import { Link } from "react-router-dom";
//Images
import bg from "./../Images/bg.svg";
import arrow from "./../Images/arrow.svg";

const ContentSubSection = ({ img, header, paragraph, link }) => {
  return (
    <div className="contentSubSection">
      <div className="imgDiv">
        <img className="contentImg" src={img} alt={header} />
        <img className="contentBg" src={bg} alt="bg" />
      </div>
      <div className="contentDiv">
        <h2 className="contentHeader">{header}</h2>
        <p className="contentSubHeader">{paragraph}</p>
        <div className="seeMoreBtn">
          <Link className="link" to={link}>
            See more
          </Link>
          <img className="arrow" src={arrow} alt="arrow" />
        </div>
      </div>
    </div>
  );
};
export default ContentSubSection;
