// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../Images/footerBg.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  padding: 5rem 10.5rem;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) #0082ff;
}
.footer .footerHeader {
  color: #fff;
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 500;
}
.footer .contactDiv {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.5rem;
}
.footer .contactDiv p {
  font-size: 1.5rem;
  color: #fff;
}

@media (max-width: 600px) {
  .footer {
    padding: 2rem 1.5rem;
  }
  .footer .footerHeader {
    font-size: 1.25rem;
  }
  .footer .contactDiv p {
    font-size: 1rem;
  }
  .footer .contactDiv img {
    width: 1.5rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Footer.scss"],"names":[],"mappings":"AAEA;EACE,qBAAA;EACA,2DAAA;AADF;AAEE;EACE,WAAA;EACA,eAAA;EACA,mBAAA;EACA,gBAAA;AAAJ;AAEE;EACE,aAAA;EACA,WAAA;EACA,mBAAA;EACA,qBAAA;AAAJ;AACI;EACE,iBAAA;EACA,WAAA;AACN;;AAIA;EACE;IACE,oBAAA;EADF;EAEE;IACE,kBAAA;EAAJ;EAGI;IACE,eAAA;EADN;EAGI;IACE,aAAA;EADN;AACF","sourcesContent":["@import \"./../Colors/color.scss\";\r\n\r\n.footer {\r\n  padding: 5rem 10.5rem;\r\n  background: url(\"./../Images/footerBg.svg\") $primary-color;\r\n  .footerHeader {\r\n    color: #fff;\r\n    font-size: 2rem;\r\n    margin-bottom: 1rem;\r\n    font-weight: 500;\r\n  }\r\n  .contactDiv {\r\n    display: flex;\r\n    gap: 0.5rem;\r\n    align-items: center;\r\n    margin-bottom: 0.5rem;\r\n    p {\r\n      font-size: 1.5rem;\r\n      color: #fff;\r\n    }\r\n  }\r\n}\r\n\r\n@media (max-width: 600px) {\r\n  .footer {\r\n    padding: 2rem 1.5rem;\r\n    .footerHeader {\r\n      font-size: 1.25rem;\r\n    }\r\n    .contactDiv {\r\n      p {\r\n        font-size: 1rem;\r\n      }\r\n      img {\r\n        width: 1.5rem;\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
