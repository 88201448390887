import React from "react";
// Stylesheet
import "./ErrorPage.scss";
//Images
const ErrorPage = () => {
  return (
    <div className="">
      <h2>Error Page</h2>
    </div>
  );
};
export default ErrorPage;
