// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feature .featureText {
  font-weight: 500;
  font-size: 1.25rem;
  color: #fff;
}

@media (max-width: 600px) {
  .feature {
    width: 30%;
  }
  .feature .featureText {
    font-size: 0.75rem;
  }
  .feature .featureImg {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Feature.scss"],"names":[],"mappings":"AAEE;EACE,gBAAA;EACA,kBAAA;EACA,WAAA;AADJ;;AAKA;EACE;IACE,UAAA;EAFF;EAGE;IACE,kBAAA;EADJ;EAGE;IACE,WAAA;EADJ;AACF","sourcesContent":[".feature {\r\n  // flex-grow: 1;\r\n  .featureText {\r\n    font-weight: 500;\r\n    font-size: 1.25rem;\r\n    color: #fff;\r\n  }\r\n}\r\n\r\n@media (max-width: 600px) {\r\n  .feature {\r\n    width: 30%;\r\n    .featureText {\r\n      font-size: 0.75rem;\r\n    }\r\n    .featureImg {\r\n      width: 100%;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
