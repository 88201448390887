import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Homepage from "./Pages/Homepage";
import AboutUsPage from "./Pages/AboutUsPage";
import ErrorPage from "./Pages/ErrorPage";
import RootLayout from "./Layout/RootLayout";
import SubPages from "./Pages/SubPages";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      { index: true, element: <Homepage /> },
      { path: "/about-us", element: <AboutUsPage /> },
      { path: "/:benefitId", element: <SubPages /> },
    ],
    errorElement: <ErrorPage />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
