import React from "react";
// Stylesheet
import "./Capabilities.scss";
import ContentSubSection from "./ContentSubSection";
//Images
import industry from "./../Images/Industrial.svg";
import realEstate from "./../Images/real estate.svg";

const contentData = [
  {
    img: industry,
    header: "Industrial VR Simulation",
    paragraph:
      "A powerful tool that revolutionizes how industries plan and optimize their operations. With this technology, you can step into a virtual replica of your facility, visualize complex processes, and fine-tune logistics with unprecedented precision.",
    link: "/industrial-vr-simulation",
  },
  {
    img: realEstate,
    header: "Real Estate Visualization",
    paragraph:
      "Our Virtual Reality for Real Estate Planning product is a game-changer in the industry. It allows real estate professionals, architects, and developers to step inside their projects before a single brick is laid.",
    link: "/real-estate-visualization",
  },
];

const Capabilities = () => {
  return (
    <div className="capabilities">
      <h2 className="sectionHeader">Capabilities</h2>
      <div className="contentSection">
        {contentData.map((data, i) => (
          <ContentSubSection
            key={i}
            img={data.img}
            header={data.header}
            paragraph={data.paragraph}
            link={data.link}
          />
        ))}
      </div>
    </div>
  );
};
export default Capabilities;
