import React from "react";
// Stylesheet
import "./FeaturesSection.scss";
import Feature from "./Feature";
//Images
import line from "./../Images/line.svg";

const FeaturesSection = ({ features }) => {
  return (
    <div className="featuresSection">
      <img src={line} alt="line" />
      <div className="features">
        {features.map((feature, i) => (
          <Feature key={i} text={feature.text} img={feature.img} />
        ))}
      </div>
      <img src={line} alt="line" />
    </div>
  );
};
export default FeaturesSection;
